import React from 'react';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import '../styles/typography.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_lang from '../src/locales/en.json';
import vi_lang from '../src/locales/vi.json';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: { translation: en_lang },
      vi: { translation: vi_lang },
    },
    lng: 'vi', // if you're using a language detector, do not define the lng option
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Component {...pageProps} />
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
